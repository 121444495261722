import { locales } from "@i18next/ui";
import { useTranslated, type Locale } from "@i18next/utils";
import { useEffect, useState } from "react";

interface Props {
  currentSite: Page.Site;
}

const countryCodes:Record<Locale, Array<string>> = {
  default: ["US"],
  sg: ["SG", "ID", "PH", "BD", "BN", "KH", "IN", "MO", "MY", "MN", "MM", "NP", "LK", "VN", "TH"],
  uk: ["GB", "AW", "DK", "SE", "NO", "FI", "IS", "AX", "FO", "GL"],
  au: ["AU", "NZ"],
  fr: [],
  de: [],
  nl: [],
  es: [],
  cn: [],
  hk: [],
  id: [],
  jp: [],
  kr: [],
  tw: []
};

function countryCode2Locale(code: string) {
  const _code = code?.toUpperCase();
  var locale:Locale | undefined;
  Object.entries(countryCodes).forEach(([l, arr]) => {
    if(arr.includes(_code))
      locale = l as Locale;
  });
  return locale;
}

const UserCountry: React.FC<Props> = ({ currentSite }) => {
  const { tr } = useTranslated(currentSite.locale);
  const [modalVisible, setModalVisible] = useState(false);
  const [locale, setLocale] = useState<Locale>("default");
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    const storedChoice = JSON.parse(localStorage.getItem("countryModalChoice") || '{}');
    if(storedChoice.choice === "dontShowAgain")
      return;
    //const defaultCountryCode = "dk";
    const defaultCountryCode = (window as any).Osano?.cm?.countryCode;
    setDontShowAgain(!!storedChoice.choice);
    const locale = storedChoice.locale || countryCode2Locale(defaultCountryCode);
    if (!!locale) {
      setLocale(locale);
      if(locale !== currentSite.locale) {
        const url = tr(currentSite.pathname, locale);
        try {
          fetch(url, { method: 'HEAD' }).then(response => {
            setUrl(response.ok ? url : tr("", locale));
            if(storedChoice.choice != "dontShowAgain") {
              setModalVisible(true);
            };
          });
        } catch (error) {
          console.error("Error checking URL validity:", error);
        }
      }
    }
  }, [currentSite.locale]);

  //Close
  const closeModal = () => {
    if(dontShowAgain) {
      const storedChoice = {
        choice: dontShowAgain && "dontShowAgain",
        locale: currentSite.locale,
      };
      localStorage.setItem("countryModalChoice", JSON.stringify(storedChoice));
    }
    setModalVisible(false);
  };

  // Stay button
  const handleStayHere = () => {
      const storedChoice = {
        choice: dontShowAgain && "dontShowAgain",
        locale: currentSite.locale
      };
      localStorage.setItem("countryModalChoice", JSON.stringify(storedChoice));
    setModalVisible(false);
  };

  //GoToRegion button
  const handleGoToRegion = () => {
    if(url) {
      const storedChoice = {
        choice: dontShowAgain && "dontShowAgain",
        locale: locale,
      };
      localStorage.setItem("countryModalChoice", JSON.stringify(storedChoice));
      window.location.href = url;
    }
  };

  return (
    <>
      {modalVisible && (
        <div id="ipModal" style={{ display: 'block' }} className="fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none bg-black-dark/50">
          <div className="pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:min-h-[calc(100%-3.5rem)] min-[576px]:max-w-[500px] transform-none opacity-100">
            <div className="pointer-events-auto relative flex w-full flex-col rounded-[10px] border-none bg-white shadow-4 pt-3.5 pb-5 px-3.5">
              <button type="button" onClick={closeModal} className="mb-7 ml-auto w-6 h-6"><img src="https://assets.avepoint.com/upload/images/icons/Close-Circle.svg" width={24} height={24} alt="Close" /></button>
              <div className="text-center">
                <div className="mb-8 space-y-1">
                  <p>You're browsing the <span className="font-semibold">{(currentSite.locale == "default" ? "us" : currentSite.locale)?.toUpperCase()} version</span> of AvePoint.</p>
                  <p>Would you like to switch to the <span className="font-semibold">{locales[locale]}</span> site?</p>
                </div>
                <button onClick={handleGoToRegion} className="btn mx-auto cursor-pointer transition duration-300 bg-primary hover:bg-primary-hover active:bg-primary-btn-active text-white group/btn w-full sm:w-fit px-4 py-2 text-sm rounded-full mb-6">
                  Yes, take me to the <span>{(locale == "default" ? "us" : locale )?.toUpperCase()}</span> site
                </button>
                <button className="underline block mx-auto text-sm" onClick={handleStayHere}>No, stay on the current site</button>
              </div>
              <div className="pl-4 pt-12">
                <label className="inline-flex items-center">
                  <input type="checkbox" checked={dontShowAgain} onChange={() => setDontShowAgain(!dontShowAgain)} className="border-split appearance-none focus:ring-0 focus:ring-white checked:bg-white checked:text-black checked:border-green checked:bg-[url('/icons/form-check.svg')] checked:bg-[length:12px_12px] rounded w-4 h-4" />
                  <span className="ml-2 text-sm">Don't show again</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserCountry;